import React from 'react';

const Intro = () => {
  return (
    <section className="app__intro">
      dea ex māchinā, Latin, <em>“goddess of the machine”</em>
    </section>
  );
}

export default Intro;