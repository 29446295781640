import React from 'react';

const Header = () => {
  return (
    <header className="app__header container">
      <h1>
        DeaExMachina
      </h1>
    </header>
  );
}

export default Header;